import React, { useEffect, useState } from "react"
import BGImg from "gatsby-background-image"
import styled from "styled-components"
import {
  medWrapper,
  colors,
  fontSizer,
  H3Black,
  B1Black,
  buttonThree,
} from "../../styles/helpers"
import { getMaxHeight } from "../../utils/helperFunc"
import SpinnerAnimation from "./SpinnerAnimation"

const QPListSection = styled.section`
  .wrapper {
    ${medWrapper};
    justify-content: flex-start;
  }

  .moreLink {
    width: 100%;
    margin: 5rem auto;
    text-align: center;

    button {
      ${buttonThree};
    }
  }
`

const QPCard = styled.div`
  width: 100%;
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc(33.33% - 2rem);
    margin: 2rem 1rem;
  }

  .qpInformation {
    background: ${colors.grey};
    @media (min-width: 1025px) {
      min-height: ${props => props.height}px;
    }
  }

  .qpImage {
    position: relative;
    min-height: 30rem;

    @media (min-width: 768px) {
      min-height: 30rem;
    }

    div {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .qpAddress {
    padding: 2rem;
    border-bottom: 0.2rem solid ${colors.black};
    h3 {
      ${H3Black};
      font-weight: normal;
      text-transform: uppercase;
    }

    &__size {
      ${B1Black};
      ${fontSizer(1.2, 1.5, 76.8, 150, 1.6)};
      margin: 0;
      margin-bottom: 1rem;
      padding: 0;
    }

    &__price {
      ${B1Black};
      margin: 0;
      padding: 0;
      color: ${colors.colorShad};
    }
  }

  .qpDetails {
    padding: 2rem;

    &__builder {
      ${B1Black};
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    &__content {
      p {
        ${B1Black};
        word-break: break-all;
      }
    }

    &__availability {
      ${B1Black};
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  }
`

const LoadingModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 134, 117, 0.75);
  z-index: 999999;

  .innerLoading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    background-color: ${colors.white};
    width: 40rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    text-align: center;

    p {
      ${B1Black};
      margin: 0;
    }

    &__spinner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-self: center;
      width: 100%;
      height: 3.5rem;
      margin: 0 auto;
    }
  }
`

const QPList = ({ data }) => {
  const DISPLAY_NUMBER = 6
  const [housesStore, setHousesStoreStore] = useState({
    max: 0,
    current: 0,
    display: [],
    loading: false,
  })
  const [maxHeight, setMaxHeight] = useState(0)
  const houses = data.edges

  useEffect(() => {
    setHousesStoreStore(prevState => {
      return {
        ...prevState,
        max: houses?.length,
        current: DISPLAY_NUMBER,
        display: houses.slice(0, DISPLAY_NUMBER),
        more: houses?.length > DISPLAY_NUMBER,
      }
    })
  }, [])

  useEffect(() => {
    const allCards = [
      ...document.querySelectorAll(".quickPosCard .qpInformation"),
    ]
    const newMaxHeight = getMaxHeight(allCards)
    setMaxHeight(newMaxHeight)
  }, [housesStore])

  const getMoreHomes = () => {
    setHousesStoreStore(prevState => {
      return {
        ...prevState,
        current: prevState.current + DISPLAY_NUMBER,
        display: houses.slice(0, prevState.current + DISPLAY_NUMBER),
        more: prevState.max > prevState.current + DISPLAY_NUMBER,
        loading: false,
      }
    })
  }

  const loadMoreHousesHandler = () => {
    setHousesStoreStore(prevState => {
      return {
        ...prevState,
        loading: true,
      }
    })

    setTimeout(() => {
      getMoreHomes()
    }, 2000)
  }

  return (
    <QPListSection>
      <div className="wrapper">
        {housesStore.display.map((house, index) => {
          const priceCheck =
            house.node.acf._coo_qucpos_price === "0" ? false : true
          const priceComma = house.node.acf._coo_qucpos_price
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

          return (
            <QPCard className="quickPosCard" key={index} height={maxHeight}>
              <div className="qpImage">
                <BGImg
                  tag="div"
                  fluid={
                    house?.node?.acf?._coo_qucpos_image?.localFile
                      ?.childImageSharp?.fluid
                  }
                  alt={house?.node?.acf?._coo_qucpos_image?.alt_text}
                />
              </div>
              <div className="qpInformation">
                <div className="qpAddress">
                  <h3>{house.node.acf._coo_qucpos_address}</h3>
                  <p className="qpAddress__size">
                    {house.node.acf._coo_qucpos_size}
                  </p>
                  <p className="qpAddress__price">
                    {!priceCheck ? `Price to come` : <>&#36;{priceComma}</>}
                  </p>
                </div>
                <div className="qpDetails">
                  <p className="qpDetails__builder">
                    {house.node.acf._coo_qucpos_builder}
                  </p>
                  <div
                    className="qpDetails__content"
                    dangerouslySetInnerHTML={{
                      __html: house.node.acf._coo_qucpos_details,
                    }}
                  />
                  <p className="qpDetails__availability">
                    Availability: {house.node.acf._coo_qucpos_timeline}
                  </p>
                </div>
              </div>
            </QPCard>
          )
        })}

        <div className="moreLink">
          <button
            disabled={!housesStore.more}
            onClick={loadMoreHousesHandler}
            type="button"
          >
            {housesStore.more ? "LOAD MORE HOMES" : "NO MORE HOMES"}
          </button>
        </div>
      </div>
      {housesStore.loading && (
        <LoadingModal>
          <div className="innerLoading">
            <div className="innerLoading__spinner">
              <SpinnerAnimation />
            </div>
            <p>Loading more quick possessions</p>
          </div>
        </LoadingModal>
      )}
    </QPListSection>
  )
}

export default QPList
