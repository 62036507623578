import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/QuickPossessions/TopQuote"
import QPList from "../components/QuickPossessions/QPList"

const FindYourLot = props => {
  const { seoInfo, topQuote, quickPossesions } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <QPList data={quickPossesions} />
    </Layout>
  )
}

export const quickPossessionsQuery = graphql`
  query quickPossessionPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    quickPossesions: allWordpressWpQuickPossessions {
      edges {
        node {
          title
          acf {
            _coo_qucpos_address
            _coo_qucpos_builder
            _coo_qucpos_details
            _coo_qucpos_price
            _coo_qucpos_size
            _coo_qucpos_timeline
            _coo_qucpos_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FindYourLot
